import React from 'react'
import { withRouter } from 'react-router-dom'
import { Row, Col } from 'reactstrap'
import { withAppContext } from '../shared/misc/AppContext'

import Layout from '../shared/layout/Layout'
import RicercaModal from '../shared/components/RicercaModal'
import RisultatiRicerca from '../shared/components/RisultatiRicerca'
import Breadcrumb from '../shared/misc/Breadcrumb'
import HeaderRisultatiRicerca from '../shared/components/HeaderRisultatiRicerca'
import PageNotFound from '../shared/misc/PageNotFound'

function Sezione({ match, appContext, ...props }) {
  const { slug } = match.params
  const { sezioni, jSezioni } = appContext
  const sezFromFF = sezioni.find((s) => s.slug === slug)
  const sezFromJ = jSezioni.find((s) => s.slug === slug)
  const currSez = sezFromFF || sezFromJ
  let source
  if (sezFromFF) source = 'f'
  if (sezFromJ) source = 'j'

  if (!sezFromFF && !sezFromJ) {
    return <PageNotFound />
  }
  return (
    <Layout className="Sezione" hasSubmenu>
      <Row className="mt-4">
        <Col sm={12}>
          <Breadcrumb source={source} />
        </Col>
      </Row>
      <Row className="mt-3 align-items-center justify-content-between">
        <Col sm={12} lg="auto">
          <h1 className="titolo-pagina">{currSez.tit}</h1>
        </Col>
        <Col sm={12} lg="auto" className="text-right">
          <RicercaModal />
        </Col>
      </Row>
      <HeaderRisultatiRicerca advanced />
      <RisultatiRicerca sez={currSez.id} key={props.location.key} />
    </Layout>
  )
}

export default withAppContext(withRouter(Sezione))
