import React, {Fragment, useState} from 'react'
import {Button, Modal, ModalBody} from 'reactstrap'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import Ricerca from './Ricerca'

export default function RicercaModal(props) {
  const [modal, setModal] = useState(false)

  const toggle = () => setModal(!modal)

  return (
    <Fragment>
      <Button className="btn-ricerca nuova" onClick={toggle}>
        <FontAwesomeIcon icon="search" />
        <FontAwesomeIcon icon="bolt" />
        <span className="ml-3">
          <span className="d-none d-lg-inline">Inizia una </span>nuova ricerca
        </span>
      </Button>
      <Modal
        isOpen={modal}
        toggle={toggle}
        className="modal-ricerca"
        size="lg"
        centered
      >
        <span className="closeModal" onClick={toggle}>
          <FontAwesomeIcon icon="times" />
        </span>
        <ModalBody>
          <Ricerca onClose={toggle} />
        </ModalBody>
      </Modal>
    </Fragment>
  )
}
