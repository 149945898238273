import React from 'react'
import { Container, Row, Col, Button } from 'reactstrap'

export default function Abbonati() {
  return (
    <Container fluid className="p-5 Abbonati">
      <Row>
        <Col md={10} className="mb-5 mx-auto text-center">
          <h1 className="titolo-pagina">Fiscal box</h1>
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col sm={12} md={10} lg={8} xl={6} className="mx-auto login-box">
          <h2 className="mb-3">Abbonati</h2>
          <p className="msg">
            I contenuti sono riservati e visualizzabili unicamente dagli abbonati a Fiscal box
          </p>
          <Button
            size="lg"
            className="submit login"
            href="https://www.fiscal-focus.it/sez,259"
          >
            Abbonati ora
          </Button>
        </Col>
      </Row>
    </Container>
  )
}
