import React, { Fragment } from 'react'
import classNames from 'classnames'
import { NavLink, withRouter } from 'react-router-dom'
import { Navbar, Nav, NavItem, NavLink as BNavLink, Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFolder } from '@fortawesome/free-regular-svg-icons'
import { withAppContext } from '../misc/AppContext'
import G4Scrollbar from '../misc/G4Scrollbar'

const getSezioniMenu = (rootSez, sezioni) => {
  const sez_menu = sezioni.valueSeq().filter((s) => s.root === rootSez.root && s.lev > 0)
  return sez_menu
}

function Submenu({ setDesktopSubMenuOpen, setMobileSubmenuOpen, root, className, ...props }) {
  const { slug } = props.match.params
  const { sezioni, jSezioni } = props.appContext
  // a seconda della "provenienza" della sezione corrente, decido quale insieme di sezioni passare
  // alla funzione che genererà il sottomenu
  const currSezFromFF = sezioni.find((s) => s.slug === slug)
  const currSezFromJ = jSezioni.find((s) => s.slug === slug)

  let setSezioni, sez_radice

  // root settata dal pulsante di apertura submenu cliccato sul menù mobile
  if (root) {
    const rootFromFF = sezioni.find((s) => s.slug === root.slug)
    const rootFromJ = jSezioni.find((s) => s.slug === root.slug)

    sez_radice = root
    if (rootFromFF) setSezioni = sezioni
    if (rootFromJ) setSezioni = jSezioni
  } else if (currSezFromFF) {
    sez_radice = currSezFromFF
    setSezioni = sezioni
  } else if (currSezFromJ) {
    sez_radice = currSezFromJ
    setSezioni = jSezioni
  }

  const sez_menu = getSezioniMenu(sez_radice, setSezioni)

  return (
    <Fragment>
      <Navbar className={`Submenu ${className}`}>
        <Button
          onClick={(e) => (setMobileSubmenuOpen ? setMobileSubmenuOpen() : setDesktopSubMenuOpen())}
          className="float-right Submenu-toggle"
        >
          <FontAwesomeIcon icon="arrow-left" className="mr-2" />
          Chiudi Menu
        </Button>
        <G4Scrollbar sidebar noScrollX>
          <Nav className={classNames({ 'pt-4': currSezFromJ })}>
            {sez_menu.map((sez, i) => {
              return (
                <NavItem
                  key={sez.id}
                  className={classNames({ 'no-link': sez.lev === 1 && currSezFromFF })}
                >
                  {sez.lev === 1 && currSezFromFF ? (
                    <span>
                      <FontAwesomeIcon icon={faFolder} className="mr-3" />
                      {sez.tit}
                    </span>
                  ) : (
                    <BNavLink tag={NavLink} to={`${sez.slug}`}>
                      {sez.slug === slug && <span className="square-active" />} {sez.tit}
                    </BNavLink>
                  )}
                </NavItem>
              )
            })}
          </Nav>
        </G4Scrollbar>
      </Navbar>
    </Fragment>
  )
}

export default withAppContext(withRouter(Submenu))
