import React from 'react'
import classNames from 'classnames'

import { NavLink, withRouter } from 'react-router-dom'
import { NavItem, NavLink as BNavLink } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function SidebarNavItems({
  sezioni,
  source,
  isMobileMenuOpen,
  setMobileSubmenuOpen,
  setMobileSubmenuRoot,
  isDesktopSubMenuOpen,
  setDesktopSubMenuOpen,
  ...props
}) {
  const iconeMenuFF = ['newspaper', 'receipt', 'briefcase', 'question-circle', 'percentage', 'info-circle', 'megaphone']
  const iconeMenuJN = ['books', 'gavel', 'balance-scale-right']
  const { slug } = props.match.params
  const isInCurrentRoot = (slug) => {
    const locationSlug = props.location.pathname.substr(1)
    const matchSez = sezioni.find((s) => s.slug === locationSlug)
    if (!matchSez) return false
    const locationRoot = sezioni.get(matchSez.root)
    const isInCurrentRoot = locationRoot.slug === slug
    return isInCurrentRoot
  }

  return (
    <>
      {sezioni
        .valueSeq()
        .filter((s) => s.lev === 0)
        .map((sez, i) => {
          return (
            <NavItem
              key={`${sez.slug}-${sez.id}`}
              className={classNames({ mobile: isMobileMenuOpen })}
            >
              <BNavLink
                tag={NavLink}
                to={`/${sez.slug}`}
                isActive={(match) => match || isInCurrentRoot(sez.slug)}
              >
                {source === 'f' ? (
                  <FontAwesomeIcon icon={iconeMenuFF[i]} className="mr-2" />
                ) : (
                  <FontAwesomeIcon icon={iconeMenuJN[i]} className="mr-2" />
                )}
                {sez.tit}
              </BNavLink>
              {(slug === sez.slug || isInCurrentRoot(sez.slug)) && !isDesktopSubMenuOpen && (
                <span
                  className="Submenu-toggle float-right"
                  onClick={(e) => {
                    e.preventDefault()
                    setDesktopSubMenuOpen()
                  }}
                >
                  <FontAwesomeIcon icon="arrow-right" />
                </span>
              )}
              {isMobileMenuOpen && (
                <span
                  className="Submenu-toggle float-right"
                  onClick={(e) => {
                    e.preventDefault()
                    setMobileSubmenuOpen(true)
                    setMobileSubmenuRoot(sez)
                  }}
                >
                  <FontAwesomeIcon icon="arrow-right" />
                </span>
              )}
            </NavItem>
          )
        })}
    </>
  )
}

export default withRouter(SidebarNavItems)
