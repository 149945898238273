import React, { Component } from 'react'
import I from 'immutable'
import { Switch, withRouter, Route } from 'react-router-dom'
import Home from './routes/Home'
import Sezione from './routes/Sezione'
import Notizia from './routes/Notizia'
import jNotizia from './routes/jNotizia'
import Risultati from './routes/Risultati'
import Abbonati from './routes/Abbonati'
import PageNotFound from './shared/misc/PageNotFound'
import { fetchDataOrError } from './shared/misc/utils'
import Loading from './shared/misc/Loading'
import ErrorBoundaryRoute from './shared/errors/error-boundary-route'
import AppContext from './shared/misc/AppContext'

import { DEV } from './shared/misc/utils'

class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      utente: null,
      sezioni: I.OrderedMap(),
      jSezioni: I.OrderedMap(),
      categorie: null,
      banner: null,
      getData: this.getData,
      infiniteScroll: false,
    }
  }

  getData = async () => {
    const [utente, sezioniList, jSezioniList, categorie, banner] = await Promise.all([
      fetchDataOrError(DEV ? '/api/utente.json' : '/api/utente'),
      fetchDataOrError(DEV ? '/api/sezioni.json' : '/api/fiscal/sezioni'),
      fetchDataOrError(DEV ? '/api/sezioni.json' : '/api/juranet/sezioni'),
      fetchDataOrError(DEV ? '/api/categorie.json' : '/api/fiscal/categorie'),
      fetchDataOrError(DEV ? '/api/banner.json' : '/api/banner'),
    ])

    const sezioni = I.OrderedMap(
      sezioniList.map((s) => {
        return [s.id, s]
      })
    )
    const jSezioni = I.OrderedMap(
      jSezioniList instanceof Error
        ? []
        : jSezioniList.map((s) => {
            s.slug = 'j-' + s.slug
            return [s.id, s]
          })
    )
    return { utente, sezioni, jSezioni, categorie, banner }
  }

  async componentDidMount() {
    const data = await this.getData()
    this.setState({ ...data })
  }

  render() {
    const { utente, sezioni, categorie } = this.state
    return (
      <Loading
        value={[utente, sezioni, categorie]}
        onRetry={this.getData}
        render={() => (
          <AppContext.Provider value={this.state}>
            <div className="App">
              <Switch>
                <ErrorBoundaryRoute exact path="/" component={Home} />
                <ErrorBoundaryRoute exact path="/risultati" component={Risultati} />
                <Route exact path="/abbonati" component={Abbonati} />
                <ErrorBoundaryRoute exact path="/f/articolo/:id" component={Notizia} />
                <ErrorBoundaryRoute exact path="/j/articolo/:id" component={jNotizia} />
                <ErrorBoundaryRoute path="/:slug" component={Sezione} />
                <ErrorBoundaryRoute component={PageNotFound} />
              </Switch>
            </div>
          </AppContext.Provider>
        )}
      />
    )
  }
}
export default withRouter(App)
