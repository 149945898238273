import React from 'react'

export default function Banner({
  banner,
  img_nome,
  img_url,
  img_google,
  link_href,
  link_title,
  link_blank,
  link_google,
  ...props
}) {
  let link_img
  if (link_href.startsWith('http')) {
    link_img = link_href
  } else {
    link_img = `https://www.fiscal-focus.it/${link_href}`
  }
  return (
    <div className="Banner">
      <figure>
        {link_href ? (
          <a href={link_img}>
            <img src={`https://www.fiscal-focus.it${img_url}`} alt={img_nome} />
          </a>
        ) : (
          <img src={`https://www.fiscal-focus.it${img_url}`} alt={img_nome} />
        )}
      </figure>
    </div>
  )
}
