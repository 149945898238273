import React, { Component, Fragment } from 'react'
import classNames from 'classnames'
import I from 'immutable'
import { withRouter } from 'react-router-dom'
import { Row, Col, Button } from 'reactstrap'
import Moment from 'react-moment'
import ReactToPrint from 'react-to-print'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withAppContext } from '../shared/misc/AppContext'
import Layout from '../shared/layout/Layout'
import RicercaModal from '../shared/components/RicercaModal'
import Breadcrumb from '../shared/misc/Breadcrumb'
import TreeView from '../shared/misc/TreeView'
import { DEV, fetchDataOrError, NotFound } from '../shared/misc/utils'
import Loading from '../shared/misc/Loading'

import logo from '../images/logo-fiscal-box.png'
import box from '../images/box.svg'
import '../styles/print.scss'
import PageNotFound from '../shared/misc/PageNotFound'

class Notizia extends Component {
  constructor(props) {
    super(props)
    this.state = {
      news: null,
      selectedParts: this.initialPartsSelected(),
      partsToShow: null,
      loadingParts: false,
      partsOpen: false,
    }
  }

  async componentDidMount() {
    const { id } = this.props.match.params
    const url = DEV ? '/api/news_70841.json' : `/api/juranet/articolo/${id}`
    const resp = await fetchDataOrError(url)

    this.setState({ news: resp }, this.handlePartsToShow)
  }

  initialPartsSelected = () => {
    const { search } = this.props.location
    const params = new URLSearchParams(search)
    const selectedParts = params.has('part') ? I.Set(params.get('part').split(',')) : I.Set([])
    return selectedParts
  }

  handlePartsSelection = (id) => {
    const { selectedParts } = this.state

    this.setState(
      {
        selectedParts: selectedParts.has(id) ? selectedParts.delete(id) : selectedParts.add(id),
      },
      () => {
        this.setSelectedPartsInUrl()
        this.handlePartsToShow()
      }
    )
  }

  setSelectedPartsInUrl = () => {
    const { selectedParts } = this.state
    const { pathname } = this.props.location
    const partsStr = selectedParts.join()
    const url = pathname + (partsStr ? '?part=' + partsStr : '')

    this.props.history.push(url)
  }

  handlePartsToShow = async () => {
    const { selectedParts, news } = this.state
    const { parti } = news

    if (!parti) {
      return
    }

    const partsToGet = parti.filter((p) => selectedParts.has(p.id))

    this.setState({
      loadingParts: true,
    })

    let partsToShow

    if (selectedParts.size === 0) {
      partsToShow = null
    } else {
      partsToShow = await Promise.all(
        partsToGet.map((part) => fetchDataOrError(`/api/juranet/articolo/${news.id}/${part.id}`))
      )
    }

    this.setState({
      loadingParts: false,
      partsToShow: partsToShow,
    })
  }

  // Assegna una classe all'indice delle parti, che attiva il toggle da CSS.
  // Il CSS è applicato solo dal tablet in giù
  toggleIndiceParti = () => {
    this.setState((prevState) => ({
      partsOpen: !prevState.partsOpen,
    }))
  }

  render() {
    const { news, print, selectedParts, partsToShow, loadingParts, partsOpen } = this.state

    if (news === NotFound) {
      return <PageNotFound />
    }

    return (
      <Layout className="Notizia">
        <Loading
          value={news}
          render={() => {
            return (
              <Fragment>
                <Row className="mt-5">
                  <Col sm={12}>
                    <Breadcrumb newsSez={news.sez} source="j" />
                  </Col>
                </Row>
                <Row className="mt-3 align-content-center justify-content-between header">
                  <Col sm={12} md="auto" className="d-flex">
                    <h5 className="data">
                      <Moment format="D MMMM YYYY" date={news.data} />
                    </h5>
                    <ReactToPrint
                      trigger={() => (
                        <Button id="print" className="d-print-none">
                          <FontAwesomeIcon icon="print" className="mr-2" />
                          <span>Stampa</span>
                        </Button>
                      )}
                      content={() => this.componentRef}
                      bodyClass="print"
                    />
                  </Col>
                  <Col sm={12} md="auto" className="text-right">
                    <RicercaModal />
                  </Col>
                </Row>

                <News
                  news={news}
                  ref={(el) => (this.componentRef = el)}
                  print={print}
                  onPartsSelection={this.handlePartsSelection}
                  selectedParts={selectedParts}
                  partsToShow={partsToShow}
                  loadingParts={loadingParts}
                  partsOpen={partsOpen}
                  toggleIndiceParti={this.toggleIndiceParti}
                />
              </Fragment>
            )
          }}
        />
      </Layout>
    )
  }
}

class News extends Component {
  render() {
    const {
      news,
      onPartsSelection,
      partsToShow,
      selectedParts,
      loadingParts,
      partsOpen,
      toggleIndiceParti,
    } = this.props
    const { tit, testo, data, parti } = news

    return (
      <div id="news">
        <div className="logo-fiscal-box d-none d-print-block">
          <img src={logo} alt="Fiscal box" />
        </div>

        <h5 className="data d-none d-print-block" style={{ display: 'none' }}>
          <Moment format="D MMMM YYYY" date={data} />
        </h5>

        <Row className="mt-3">
          <Col sm={12}>
            <h1 className={`titolo-pagina`}>{tit}</h1>
            {news.firma && (
              <p className="firma">
                Autore: <span>{news.firma}</span>
              </p>
            )}
            {(news.tipo || news.numero || news.organo || news.sede) && (
              <div className="altre-info">
                {news.tipo && (
                  <span className="tipo mr-3">
                    <strong>Tipo:</strong> {news.tipo}
                  </span>
                )}
                {news.numero && (
                  <span className="numero mr-3">
                    <strong>Numero:</strong> {news.numero}
                  </span>
                )}
                {news.organo && (
                  <span className="organo mr-3">
                    <strong>Organo:</strong> {news.organo}
                  </span>
                )}
                {news.sede && (
                  <span className="sede mr-3">
                    <strong>Sede:</strong> {news.sede}
                  </span>
                )}
              </div>
            )}
          </Col>
        </Row>
        <Row className="mt-3">
          <Col lg={parti ? 8 : 12}>
            <div className="white-box content">
              {loadingParts ? (
                <div className="Loading text-center" key="loader">
                  <img src={box} alt="Loading..." />
                </div>
              ) : partsToShow ? (
                partsToShow.map((part) => (
                  <div
                    className="p"
                    dangerouslySetInnerHTML={{ __html: part.testo }}
                    key={part.id}
                  />
                ))
              ) : testo ? (
                <div className="p" dangerouslySetInnerHTML={{ __html: testo }} />
              ) : null}
            </div>
          </Col>
          {parti && parti.length > 0 && (
            <Col lg={4} className="d-print-none news-sidebar">
              <div className={classNames('white-box indice-parti', { open: partsOpen })}>
                <div className="header" onClick={(e) => toggleIndiceParti()}>
                  Indice dell'articolo
                  <span className="toggleBtn d-lg-none">
                    <FontAwesomeIcon icon={partsOpen ? 'times' : 'angle-up'} />
                  </span>
                </div>
                <div className="menu-parti">
                  <TreeView voices={parti} onSelect={onPartsSelection} selected={selectedParts} />
                </div>
              </div>
            </Col>
          )}
        </Row>
      </div>
    )
  }
}

export default withAppContext(withRouter(Notizia))
