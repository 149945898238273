import React, { Component, Fragment } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import animateScrollTo from 'animated-scroll-to'
import axios from 'axios'
import I from 'immutable'
import _ from 'lodash'
import InfiniteScroll from 'react-infinite-scroll-component'
import Moment from 'react-moment'
import { Link, withRouter } from 'react-router-dom'
import { Badge, Col, Row, Nav, NavItem, NavLink, Input } from 'reactstrap'
import { withAppContext } from '../misc/AppContext'
import Loading, { loader } from '../misc/Loading'
import G4Pagination from '../misc/G4Pagination'
import { fetchData, parseQuery, stringifyQuery, addClass } from '../misc/utils'

export class RisultatiRicerca extends Component {
  signal = axios.CancelToken.source()
  constructor(props) {
    super(props)
    this.state = {
      docs: I.List(),
      totale: null,
      hasMore: true,
      currentPage: this.initialPage(),
      perPage: this.initialPerPage(),
      loading: false,
      selectedTab: this.initialTab(),
    }
  }

  // fa il parse ad oggetto della query string nella location.search
  getSearch() {
    const { search } = this.props.location
    return parseQuery(search)
  }

  // Ricavo la pagina corrente dai parametri nell'url
  initialPage = () => {
    const { search } = this.props.location
    const { infiniteScroll } = this.props.appContext
    const params = new URLSearchParams(search)
    const initialPage = params.has('page') ? params.get('page') : infiniteScroll ? -1 : 0
    return Number(initialPage)
  }

  // Ricavo il numero di risultati per pagina dai parametri nell'url
  initialPerPage = () => {
    const { search } = this.props.location
    const { infiniteScroll } = this.props.appContext
    const params = new URLSearchParams(search)
    const perPage = params.has('perPage') ? params.get('perPage') : infiniteScroll ? null : 10
    return Number(perPage)
  }

  // leggo la tab selezionata fra i parametri nella location.search.
  // Se non c'è un parametro tab, ritorno null (la tab selezionata sarà "TUTTI")
  initialTab = () => {
    const { search } = this.props.location
    const params = new URLSearchParams(search)
    const selectedTab = params.has('tab') ? params.get('tab') : null
    return selectedTab
  }

  // recupero gli argomenti selezionati da i parametri nella location.search
  getSelectedArgsFromUrl = () => {
    const { search } = this.props.location
    const params = new URLSearchParams(search)
    const selectedArgs = params.has('sez') ? params.get('sez').split(',') : null
    return selectedArgs
  }

  // ricostruisce la query string di ricerca aggiungendo i nuovi parametri ai vecchi
  // esegue un push della nuova url nella history
  newSearch(params) {
    const { pathname } = this.props.location
    const newSearch = _.assign(this.getSearch(), params)
    this.props.history.push(pathname + '?' + stringifyQuery(newSearch))
  }

  async componentDidMount() {
    // Lancio il primo caricamento dei contenuti
    await this.loadMore()

    // calcolo se mostrare o nascondere il pulsante scrollToTopBtn
    if (this.state.docs && this.state.docs.size > 0) {
      const winH = window.outerHeight
      const pageWrapperH = document.getElementById('page-content-wrapper').offsetHeight
      const scrollToTopBtn = document.getElementById('scrollToTopBtn')
      if (pageWrapperH <= winH) addClass(scrollToTopBtn, 'd-none')
    }
  }

  componentWillUnmount() {
    this.signal.cancel()
  }

  // funzione che carica i nuovi contenuti
  loadMore = async () => {
    if (!this.state.hasMore) return

    const apiParams = this.getSearch()
    const { infiniteScroll } = this.props.appContext

    // Se mostriamo il contenuto di una sezione (navigazione da menu)
    if (this.props.sez) {
      apiParams.sez = this.props.sez
    }

    // Se è attivo l'infinite scroll, la pagina di partenza è meno uno, e ad ogni caricamento,
    // la aumentiamo di uno per caricare i contenuti successivi;
    // altrimenti, la pagina è 1 o il valore del parametro "page" nell'url
    if (infiniteScroll) {
      apiParams.page = this.state.currentPage + 1
    } else {
      apiParams.perPage = this.state.perPage
    }

    // costruisco l'url con cui chiamare le API in base alla tab selezionata, che definisce la
    // provenienza del contenuto richiesto
    let apiBase

    switch (this.state.selectedTab) {
      case 'f':
        apiBase = '/api/fiscal/ricerca'
        break

      case 'j':
        apiBase = '/api/juranet/ricerca'
        break

      default:
        apiBase = '/api/ricerca'
        break
    }

    const apiUrl = apiBase + '?' + stringifyQuery(apiParams)

    try {
      this.setState({
        loading: true,
        docs: infiniteScroll ? this.state.docs : null,
      })
      const { page, totale, hasMore, docs } = await fetchData(apiUrl, {
        cancelToken: this.signal.token,
      })

      if (infiniteScroll) {
        if (page !== this.state.currentPage + 1) return
      } else {
        if (page !== this.state.currentPage) return
      }

      this.setState({
        currentPage: page,
        totale: totale,
        hasMore: hasMore,
        docs: infiniteScroll ? this.state.docs.concat(docs) : I.List(docs),
        pageTotal: Math.ceil(totale / this.state.perPage),
        loading: false,
      })
    } catch (error) {
      if (axios.isCancel(error)) {
        return
      } else {
        this.setState({
          docs: error,
          loading: false,
        })
      }
    }
  }

  handlePageChange = (newPage) => {
    this.setState(
      {
        currentPage: Number(newPage),
      },
      this.setNewParamsInUrl({page: newPage})
    )
  }

  handlePerPageChange = (newPerPage) => {
    const {currentPage, perPage} = this.state

    const newPage = Math.ceil(currentPage * perPage / newPerPage)

    this.setState(
      {
        perPage: Number(newPerPage),
        pageTotal: Math.ceil(this.state.totale / newPerPage),
      }, () => {
        this.setNewParamsInUrl({perPage: newPerPage, page: newPage})
      }
    )
  }

  handleTabChange = (choice) => {
    this.setState(
      {
        selectedTab: choice,
      },
      this.setNewParamsInUrl({tab: choice})
    )
  }

  setNewParamsInUrl = (newParams) => {
    const { pathname, search } = this.props.location
    const params = new URLSearchParams(search)

    for (const [name, value] of Object.entries(newParams)) {
      if (value) params.set(name, value)
      else params.delete(name)
    }

    const url = pathname + '?' + params.toString()
    this.props.history.push(url)
  }

  render() {
    const { ricerca } = this.props
    const {
      docs,
      hasMore,
      totale,
      loading,
      selectedTab,
      currentPage,
      perPage,
      pageTotal,
    } = this.state
    const { infiniteScroll } = this.props.appContext

    const argomenti = this.getSelectedArgsFromUrl()
    // Per convenzione interna, gli id delle sezioni di Fiscal Focus sono sempre pari, mentre gli id
    // delle sezioni di juranet sempre dispari
    const selectedFiscalArgs = argomenti
      ? argomenti.filter((a) => Number(a) % 2 === 0).length
      : null
    const selectedJuranetArgs = argomenti ? argomenti.filter((a) => Number(a) % 2).length : null
    const showTabs =
      (ricerca && !argomenti) || (ricerca && selectedFiscalArgs && selectedJuranetArgs)

    const perPageOptions = [10, 20, 50]

    return (
      <Fragment>
        <Row className="totale">
          {totale !== null && (
            <Col>
              <FontAwesomeIcon icon="list-alt" className="mr-2" />
              <strong>Trovati {totale} risultati</strong>
            </Col>
          )}
        </Row>

        {!!showTabs && (
          <Row className="tab-risultati mt-4">
            <Col>
              <Nav tabs>
                <NavItem className="all">
                  <NavLink
                    tag="span"
                    active={!selectedTab}
                    onClick={(e) => this.handleTabChange(null)}
                  >
                    Tutti
                  </NavLink>
                </NavItem>
                <NavItem className="fiscale">
                  <NavLink
                    tag="span"
                    active={selectedTab === 'f'}
                    onClick={(e) => this.handleTabChange('f')}
                  >
                    Editoria
                  </NavLink>
                </NavItem>
                <NavItem className="giuridico">
                  <NavLink
                    tag="span"
                    active={selectedTab === 'j'}
                    onClick={(e) => this.handleTabChange('j')}
                  >
                    Banca Dati
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
          </Row>
        )}

        <Row className="mt-3">
          <Col className="results-col-container">
            <Loading
              value={docs}
              render={() => (
                <>
                  {docs.size > 0 && (
                    <div id="scrollToTopBtn" className="scrollToTopBtn">
                      <div>
                        <FontAwesomeIcon
                          icon="arrow-circle-up"
                          onClick={(e) =>
                            animateScrollTo(0, {
                              maxDuration: 1000,
                              minDuration: 250,
                              speed: 300,
                            })
                          }
                        />
                      </div>
                    </div>
                  )}
                  {!loading && docs.size === 0 && (
                    <div className="risultati-container white-box">
                      <p className="no-risultati">
                        Non ci sono risultati. Modifica i filtri nella barra laterale o inizia una
                        nuova ricerca.
                      </p>
                    </div>
                  )}
                  {infiniteScroll ? (
                    <InfiniteScroll
                      dataLength={docs.size}
                      next={this.loadMore}
                      hasMore={hasMore}
                      loader={loader}
                    >
                      {docs.map((doc) => this.renderItem(doc))}
                    </InfiniteScroll>
                  ) : (
                    <>
                      {docs.map((doc) => this.renderItem(doc))}
                      {docs.size > 0 && totale > perPage && (
                        <Row className="mt-5 justify-content-between">
                          <Col sm={{ size: 'auto' }}>
                            <div className="d-flex align-items-center">
                              <span className="mr-2">Mostra</span>
                              <Input
                                type="select"
                                value={perPage}
                                onChange={(e) => this.handlePerPageChange(Number(e.target.value))}
                              >
                                {perPageOptions.map((o) => (
                                  <option key={o} value={o}>
                                    {o}
                                  </option>
                                ))}
                              </Input>
                              <span className="ml-2">risultati</span>
                            </div>
                          </Col>
                          <Col sm={{ size: 'auto' }}>
                            <G4Pagination
                              currentPage={currentPage}
                              pageTotal={pageTotal}
                              pageDelta={4}
                              onPageChange={this.handlePageChange}
                            />
                          </Col>
                        </Row>
                      )}
                    </>
                  )}
                </>
              )}
            />
          </Col>
        </Row>
      </Fragment>
    )
  }

  renderItem = (r, i) => {
    const { sezioni, jSezioni, categorie } = this.props.appContext
    const arg = sezioni.get(r.sez) || jSezioni.get(r.sez)

    const parent = sezioni.get(r.sez[0]) || jSezioni.get(r.sez[0])
    let rHref, bgColor, dbLabel
    switch (r.source) {
      case 'f':
        rHref = `/f/articolo/${r.id}`
        bgColor = 'green'
        dbLabel = 'Editoria'
        break
      case 'j':
        rHref = `/j/articolo/${r.id}`
        bgColor = 'red'
        dbLabel = 'Banca Dati'
        break
      default:
        break
    }

    return (
      <Row className=" mt-3 " key={r.id}>
        <Col sm={12}>
          <div className="white-box notizia">
            <div className="info">
              <div className="mr-2">{i}</div>
              <div className="arg">
                {arg && <span className="square">{arg.tit.replace(/^.*'/, '')[0]}</span>}
              </div>
              <div className="parent">{parent && parent.tit}</div>
              <div className="categorie">
                <>
                  {r.cat &&
                    r.cat.map((cn) => (
                      <Badge size="sm" key={cn}>
                        {categorie.find((c) => c.id === cn).tit}
                      </Badge>
                    ))}
                </>
              </div>
              <div className="sorgente">
                <Badge size="lg" color={bgColor}>
                  {dbLabel}
                </Badge>
              </div>
            </div>
            <div className="testi">
              <h5 className="data">
                <Moment format="D MMMM YYYY" date={r.data && r.data} />
              </h5>
              <h2 className="tit">
                <Link className="stretched-link" to={{pathname: rHref, state: this.state}}>
                  {r.tit}
                </Link>
              </h2>
              <h3 className="stit">{r.stit && r.stit}</h3>
              {r.testo && <p dangerouslySetInnerHTML={{ __html: r.testo }} />}
              {r.hl && (
                <p>
                  {r.hl.map((hl, i) => (
                    <span key={i} dangerouslySetInnerHTML={{ __html: hl }} />
                  ))}
                </p>
              )}
              <div className="altre-info">
                {r.tipo && (
                  <span className="tipo mr-3">
                    <strong>Tipo:</strong> {r.tipo}
                  </span>
                )}
                {r.numero && (
                  <span className="numero mr-3">
                    <strong>Numero:</strong> {r.numero}
                  </span>
                )}
                {r.organo && (
                  <span className="organo mr-3">
                    <strong>Organo:</strong> {r.organo}
                  </span>
                )}
                {r.sede && (
                  <span className="sede mr-3">
                    <strong>Sede:</strong> {r.sede}
                  </span>
                )}
              </div>
            </div>
          </div>
        </Col>
      </Row>
    )
  }
}

export default withAppContext(withRouter(RisultatiRicerca))
