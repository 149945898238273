import React, { useState } from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { Navbar, NavbarBrand, Nav, NavItem, NavLink as BNavLink } from 'reactstrap'
import { withAppContext } from '../misc/AppContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import G4Scrollbar from "../misc/G4Scrollbar";
import SidebarNavItems from '../components/SidebarNavItems'
import Submenu from './Submenu'
import logo from '../../images/logo.png'

function Sidebar(props) {
  const [isMobileSubmenuOpen, setMobileSubmenuOpen] = useState(false)
  const [mobileSubmenuRoot, setMobileSubmenuRoot] = useState(null)
  const { setDesktopSubMenuOpen, isDesktopSubMenuOpen, isMobileMenuOpen } = props
  const { sezioni, jSezioni, utente } = props.appContext

  return (
    <div className={classNames('sidebar-container', { mobile: isMobileMenuOpen })}>
      <Navbar className={classNames('Sidebar', { open: isMobileMenuOpen })}>
        <NavbarBrand href="/">
          <img src={logo} alt="Fiscal box" />
        </NavbarBrand>
        <G4Scrollbar sidebar noScrollX>
          <Nav>
            <NavItem>
              <BNavLink tag={Link} to="/">
                <FontAwesomeIcon icon="home" className="mr-2" />
                Home
              </BNavLink>
            </NavItem>
            <SidebarNavItems
              sezioni={sezioni}
              source={'f'}
              isMobileMenuOpen={isMobileMenuOpen}
              setMobileSubmenuOpen={setMobileSubmenuOpen}
              setMobileSubmenuRoot={setMobileSubmenuRoot}
              isDesktopSubMenuOpen={isDesktopSubMenuOpen}
              setDesktopSubMenuOpen={setDesktopSubMenuOpen}
            />
            {utente.juranet && (
              <SidebarNavItems
                sezioni={jSezioni}
                source={'j'}
                isMobileMenuOpen={isMobileMenuOpen}
                setMobileSubmenuOpen={setMobileSubmenuOpen}
                setMobileSubmenuRoot={setMobileSubmenuRoot}
                isDesktopSubMenuOpen={isDesktopSubMenuOpen}
                setDesktopSubMenuOpen={setDesktopSubMenuOpen}
              />
            )}
          </Nav>
        </G4Scrollbar>
      </Navbar>
      <div id="fiscal-focus" className={isMobileMenuOpen ? 'mobile' : null}>
        <p>Un progetto di:</p>
        <h4>Fiscal Focus</h4>
      </div>
      {isMobileMenuOpen && mobileSubmenuRoot && (
        <Submenu
          className={classNames({
            open: isMobileSubmenuOpen && mobileSubmenuRoot,
            closed: !(isMobileSubmenuOpen && mobileSubmenuRoot),
          })}
          root={mobileSubmenuRoot}
          setMobileSubmenuOpen={setMobileSubmenuOpen}
        />
      )}
    </div>
  )
}

export default withAppContext(Sidebar)
