import React from 'react'
import {Alert, Button} from 'reactstrap'
import {NotFound, Forbidden} from '../misc/utils'

import box from '../../images/box.svg'

export const loader = (
  <div className="Loading text-center" key="loader">
    <img src={box} alt="Loading..." />
  </div>
)

export default function Loading({value, onRetry, render, children}) {
  const values = value instanceof Array ? value : [value]

  // se uno dei valori è Forbidden, eseguo un reload della pagina
  if (values.some(x => x === Forbidden)) {
    window.location.reload()
    return
  }

  // pulsante per riprovare l'azione che ha causato un errore, se possibile
  // (chi usa Loading deve passare una prop onRetry per abilitarlo)
  const retryButton = onRetry && (
    <Button color="dark" onClick={onRetry}>
      OK
    </Button>
  )

  // se uno dei valori è NotFound, mostro questo perché è il più sensato
  if (values.some(x => x === NotFound)) {
    return (
      <Alert color="danger" className="error animated slideInRight">
        <h3>Not found</h3>
        <p>
          Siamo spiacenti, il contenuto che hai richisto non è al momento
          disponibile.
        </p>
        <p>Prova a ricaricare la pagina o riprova più tardi.</p>
        <p>In caso di errori persistenti, contattare l'assistenza.</p>
        {retryButton}
      </Alert>
    )
  }

  // se uno dei valori è un altro tipo di errore (Error), lo mostro
  const error = values.find(x => x instanceof Error)
  if (error) {
    return (
      <Alert color="danger" className="error animated slideInRight">
        <h3> </h3>
        <p>Siamo spiacenti, il server ha riscontrato un errore.</p>
        <p>Prova a ricaricare la pagina o riprova più tardi.</p>
        <p>In caso di errori persistenti, contattare l'assistenza.</p>
        <details className="preserve-space">
          <pre>
            {error.name}: {error.message}
          </pre>
        </details>
        {retryButton}
      </Alert>
    )
  }

  // se uno dei valori è null, mostro la gif di loading
  if (values.some(x => x === null || x === undefined)) {
    // console.log('loading')
    return loader
  }

  // altrimenti tutti i valori sono validi (inclusi bool) e mostro il contenuto
  // protetto da Loading, che può essere una render prop oppure children
  if (render) {
    return render(value)
  } else {
    return children
  }
}
