import React, {useState} from 'react'
import classNames from 'classnames'
import {Button} from 'reactstrap'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {deleteAllCookies} from './utils'

export default function TopbarLogin({utente}) {
  const [isOpen, setIsOpen] = useState()
  const toggle = () => setIsOpen(!isOpen)

  const doLogout = () => {
    deleteAllCookies()
    window.location = 'http://www.fiscal-box.it'
  }

  return (
    <>
      <div className="topbar-login" onClick={toggle}>
        <span className="circle">
          <FontAwesomeIcon icon="user" />
        </span>
        <span className="name">
          {utente ? `${utente.nome} ${utente.cognome}` : 'Login'}
        </span>
      </div>
      <div
        className={classNames('topbar-login-dropdown', {
          open: isOpen,
        })}
      >
        <Button
          className={classNames('logout', {'d-none': !isOpen})}
          onClick={doLogout}
        >
          Logout
        </Button>
      </div>
    </>
  )
}
