import React from 'react'
import axios from 'axios'
import queryString from 'query-string'

export const Forbidden = Symbol('Forbidden')
export const NotFound = Symbol('NotFound')

export async function fetchData(path, options) {
  const resp = await axios.get(path, options)
  return resp.data
}

export const sortMap = [
  {code: 'data desc', label: 'data (dal più recente)'},
  {code: 'data asc', label: 'data (dal più vecchio)'},
  {code: 'score desc', label: 'rilevanza'},
  {code: 'tit asc', label: 'titolo'},
]

export const DEV = false

export async function fetchDataOrError(path) {
  try {
    return await fetchData(path)
  } catch (error) {
    if (error.response.status === 403) {
      return Forbidden
    }
    if (error.response.status === 404) {
      return NotFound
    }
    return error
  }
}

// Versione di setState usabile con async/await
export function setStateAndWait(newState) {
  return new Promise((resolve, reject) => {
    try {
      this.setState(newState, resolve)
    } catch (err) {
      reject(err)
    }
  })
}

export const parseQuery = query =>
  queryString.parse(query, {
    arrayFormat: 'comma',
    parseNumbers: true,
  })

export const stringifyQuery = query =>
  queryString.stringify(query, {
    arrayFormat: 'comma',
    skipNull: true,
    skipEmptyString: true,
  })

export function getOBJECTid(o) {
  let _id = 0
  if (typeof o.__uniqueid == 'undefined') {
    Object.defineProperty(o, '__uniqueid', {
      value: ++_id,
      enumerable: false,
      // This could go either way, depending on your
      // interpretation of what an "id" is
      writable: false,
    })
  }

  return o.__uniqueid
}

export function addClass(element, className) {
  if (element.classList.contains(className)) {
    element.classList.remove(className)
  } else {
    element.classList.add(className)
  }
}

// a custom component that renders the first child passed to it directly
export function FirstChild(props) {
  const childrenArray = React.Children.toArray(props.children)
  return childrenArray[0] || null
}

//Verifica che il browser dell'utente supporti l'input type date
export function isDateSupported() {
  let input = document.createElement('input')
  let value = 'a'
  input.setAttribute('type', 'date')
  input.setAttribute('value', value)
  return input.value !== value
}

export function deleteAllCookies() {
  document.cookie.split(/; */).forEach(cookie => {
    let name = cookie.replace(/=.*/, '')
    document.cookie = [
      `${name}=`,
      'domain=fiscal-focus.it',
      'expires=Thu, 01 Jan 1970 00:00:00 GMT',
    ].join(';')
  })
}
