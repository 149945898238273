import React from 'react'
import {Route, Redirect} from 'react-router-dom'
import {withAppContext} from './AppContext'

function LoggedRoute({appContext, exact, path, children, ...props}) {
  const {utente} = appContext
  const loginUrl =
    'https://www.fiscal-focus.it/form/login,12,6?ret=http://box.fiscal-focus.it/'
  return (
    <Route exact={exact} path={path} {...props}>
      {checkUser(utente, children, loginUrl)}
    </Route>
  )
}

function checkUser(utente, children, loginUrl) {
  // Se utente = null, redirect alla login su fiscalfocus.it
  // Se c'è utente e ha flag fiscalbox, mostro il componente per la Route
  // Altrimenti redirect a pagina abbonati a fiscalbox
  if (!utente.logged) {
    return window.location.replace(loginUrl)
  } else if (utente.fiscalbox) {
    return children
  } else {
    return <Redirect to="/abbonati" />
  }
}

export default withAppContext(LoggedRoute)
