import React from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import { Col, Nav, NavItem, NavLink as BNavLink, Row } from 'reactstrap'
import HeaderRisultatiRicerca from '../shared/components/HeaderRisultatiRicerca'
import RicercaModal from '../shared/components/RicercaModal'
import RisultatiRicerca from '../shared/components/RisultatiRicerca'
import Layout from '../shared/layout/Layout'
import ModificaRicercaMobile from '../shared/components/ModificaRicercaMobile'

function Risultati(props) {
  return (
    <Layout className="Risultati" hasRicerca>
      <Row className="mt-4">
        <Col sm={12}>
          <Nav className="Breadcrumb">
            <NavItem>
              <BNavLink tag={NavLink} to="/">
                <span>Home</span>
                <span className="divider">/</span>
              </BNavLink>
            </NavItem>
            <NavItem>
              <BNavLink tag={NavLink} to="/" className="current disabled">
                <span>Risultati della ricerca</span>
              </BNavLink>
            </NavItem>
          </Nav>
        </Col>
      </Row>
      <Row className="mt-3 align-items-center justify-content-between">
        <Col sm={12} lg="auto">
          <h1 className="titolo-pagina">Risultati della ricerca</h1>
        </Col>
        <Col sm={12} lg="auto" className="text-right">
          <ModificaRicercaMobile />
          <RicercaModal />
        </Col>
      </Row>
      <HeaderRisultatiRicerca />
      <RisultatiRicerca key={props.location.key} ricerca={true} />
    </Layout>
  )
}

export default withRouter(Risultati)
