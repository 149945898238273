import React from 'react'
import {Link} from 'react-router-dom'
import {Button} from 'reactstrap'
import Layout from '../layout/Layout'

function PageNotFound(props) {
  return (
    <Layout className="PageNotFound">
      <div className="warning text-center py-5">
        <h1 className="mb-2">404</h1>
        <h2 className="mb-2">Ops!</h2>
        <h3 className="mb-3">
          Siamo spiacenti, ma il contenuto richiesto non è al momento
          disponibile.
        </h3>
        <p className="mb-2"></p>
        <Button tag={Link} to="/" size="lg" color={'primary'}>
          Torna all'home page
        </Button>
      </div>
    </Layout>
  )
}

export default PageNotFound
