import React from 'react'
import { Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withAppContext } from '../shared/misc/AppContext'
import Layout from '../shared/layout/Layout'
import Banner from '../shared/misc/Banner'
import Ricerca from '../shared/components/Ricerca'

import cubeLogo from '../images/box.svg'

function Home(props) {
  const { banner } = props.appContext
  return (
    <Layout className="Home">
      <Row className="mt-5">
        <Col md={10} lg={9}>
          <h1 className="titolo-pagina">Fiscal box</h1>
          <p>Benvenuto.</p>
          <p>
            Per iniziare utilizza il menu principale,{' '}
            <span className="d-lg-none">
              cliccando sull'icona <FontAwesomeIcon icon="bars" /> in alto a sinistra,{' '}
            </span>
            oppure cerca tra tutti i contenuti del portale tramite il pannello sottostante.
          </p>
        </Col>
        <Col md={2} lg={3} className="d-none d-md-block logo-faded">
          <img src={cubeLogo} alt="Fiscal box" />
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={9}>
          <Ricerca />
        </Col>
        <Col md={12} lg={3} className="banners mt-4">
          {banner.slice(0, 2).map((b, i) => (
            <Banner {...b} key={`${b.banner}-${i}`} />
          ))}
        </Col>
      </Row>
    </Layout>
  )
}

export default withAppContext(Home)
