import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import React, { Fragment, useState } from 'react'
import { DebounceInput } from 'react-debounce-input'
import { withRouter } from 'react-router-dom'
import { withAppContext } from '../misc/AppContext'
import { Col, Input, FormText, Row, Collapse, Button } from 'reactstrap'
import { parseQuery, sortMap, stringifyQuery } from '../misc/utils'
import { FiltroAnno, FiltroIntervalloDate } from '../components/FiltriDataAnno'
import I from 'immutable'

function HeaderRisultatiRicerca(props) {
  const { advanced } = props
  const { search, pathname } = props.location
  const { slug } = props.match.params
  const { sezioni } = props.appContext
  const fSezSet = I.Set(sezioni.map((s) => s.slug).toList())
  const hideNumeroDocumentoFilter = fSezSet.has(slug)

  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)

  function getSearch() {
    return parseQuery(search)
  }

  function newSearch(params) {
    const newSearch = _.assign(getSearch(), params)
    props.history.push(pathname + '?' + stringifyQuery(newSearch))
  }

  function handleDate(dateParams) {
    newSearch(dateParams)
  }

  function handleYear(dateParams) {
    newSearch(dateParams)
  }

  function handleNumeroDocumento(numero) {
    newSearch({ numero: numero })
  }

  const { q, sort, dateType, endDate, startDate, year, numero } = getSearch()
  const date = { dateType, endDate, startDate }

  return (
    <Fragment>
      <Row className="cerca-risultati">
        <Col sm={12}>
          <div className="text-search white-box">
            <span className="square">
              <FontAwesomeIcon icon="search" />
            </span>
            <DebounceInput
              value={q || ''}
              className="form-control"
              minLength={3}
              debounceTimeout={800}
              onChange={(e) => newSearch({ q: e.target.value })}
              placeholder="Cerca fra i risultati"
            />
          </div>
        </Col>
      </Row>
      {advanced && (
        <>
          <Collapse isOpen={isOpen}>
            <Row className="filtri-ricerca mt-3">
              <Col sm={12}>
                <FiltroIntervalloDate dateSelected={date} onDateFilterChange={handleDate} header />
              </Col>
              <Col sm={12} className="mt-3">
                <FiltroAnno
                  dateSelected={date}
                  onDateFilterChange={handleYear}
                  placeholder={'Filtra per anno'}
                  showLastMonth
                  showLastYear
                  yearDefaultValue={year}
                />
              </Col>
              <Col sm={12} lg={6} className="mt-3">
                {!hideNumeroDocumentoFilter && (
                  <>
                    <Input
                      value={numero || ''}
                      onChange={(e) => handleNumeroDocumento(e.target.value)}
                      placeholder={'Filtra per numero documento'}
                    />
                    <FormText>Inserisci il numero documento</FormText>
                  </>
                )}
              </Col>
            </Row>
          </Collapse>

          <Row className={'mt-3'}>
            <Col sm={12} className={'text-center'}>
              <Button color={'info'} onClick={toggle}>
                {isOpen ? 'Nascondi ' : 'Mostra '}filtri aggiuntivi
              </Button>
            </Col>
          </Row>
        </>
      )}

      <Row className="filtri mt-3">
        <Col className="text-right">
          Ordina per:
          <Input
            type="select"
            onChange={(e) => newSearch({ sort: e.target.value })}
            value={sort || ''}
          >
            <option key="default" value="">
              (predefinito)
            </option>
            {sortMap.map((o) => (
              <option key={o.code} value={o.code}>
                {o.label}
              </option>
            ))}
          </Input>
        </Col>
      </Row>
    </Fragment>
  )
}

export default withAppContext(withRouter(HeaderRisultatiRicerca))
