import React, { useState } from 'react'
import classNames from 'classnames'
import DatePicker from 'react-datepicker'
import {
  Row,
  Col,
  Input,
  Button,
  InputGroup,
  InputGroupAddon,
  FormFeedback,
  FormText,
} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DebounceInput } from 'react-debounce-input'
import 'react-datepicker/dist/react-datepicker.css'
import { registerLocale } from 'react-datepicker'
import it from 'date-fns/locale/it'
import { isDateSupported } from '../misc/utils'
import moment from 'moment'
registerLocale('it', it)

const TYPE = {
  // enum (Java, C, C++) o Algebraic Datatype
  LASTMONTH: 'lastMonth',
  LASTYEAR: 'lastYear',
  YEAR: 'year',
  INTERVAL: 'interval',
}

const ERROR = {
  TOOLONG: 'Il valore non può essere più lungo di 4 cifre',
  ONLYNUMBERS: 'Sono consentiti solo valori numerici',
  INVALIDYEAR: 'Inserire un anno valido',
  TOOSHORT: 'Il valore deve essere formato da 4 cifre',
}

const dateToIsoString = (date) => {
  const isValid = moment(date).isValid()
  if (date !== null && isValid) return date.toISOString().substr(0, 10)
  return null
}

export function FiltroIntervalloDate(props) {
  const { small, header, onDateFilterChange, dateSelected } = props
  const { startDate, endDate } = dateSelected

  const handleDateChange = (dateName, dateValue) => {
    onDateFilterChange({
      ...dateSelected,
      [dateName]: dateToIsoString(dateValue),
      dateType: dateValue ? TYPE.INTERVAL : null,
      year: null,
    })
  }

  return (
    <div id="filtri-data">
      <Row className="custom-interval">
        <Col sm={12} lg={small ? 12 : 6}>
          <InputGroup>
            <InputGroupAddon addonType="prepend">Da</InputGroupAddon>
            {isDateSupported() ? (
              small || header ? (
                <DebounceInput
                  className="form-control"
                  type="date"
                  value={startDate || ''}
                  onChange={(e) => handleDateChange('startDate', new Date(e.target.value))}
                  debounceTimeout={3000}
                />
              ) : (
                <Input
                  type="date"
                  value={startDate || ''}
                  onChange={(e) => handleDateChange('startDate', new Date(e.target.value))}
                />
              )
            ) : (
              <DatePicker
                className="form-control"
                id="from"
                filterDate={(date) =>
                  date.getYear() > 108 && date.getYear() <= new Date().getYear()
                }
                dateFormat="dd/MM/yyy"
                selected={startDate ? new Date(startDate) : null}
                onChange={(date) => handleDateChange('startDate', date)}
                openToDate={new Date()}
                locale="it"
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                selectsStart
                startDate={new Date(startDate)}
                endDate={new Date(endDate)}
                popperModifiers={{
                  offset: {
                    enabled: true,
                    offset: '5px, 10px',
                  },
                  preventOverflow: {
                    enabled: true,
                    escapeWithReference: false,
                    boundariesElement: 'viewport',
                  },
                }}
              />
            )}
          </InputGroup>
        </Col>
        <Col sm={12} lg={small ? 12 : 6} className="mt-3 mt-lg-0">
          <InputGroup>
            <InputGroupAddon addonType="prepend">A</InputGroupAddon>
            {isDateSupported() ? (
              small || header ? (
                <DebounceInput
                  className="form-control"
                  type="date"
                  value={endDate || ''}
                  onChange={(e) => handleDateChange('endDate', new Date(e.target.value))}
                  debounceTimeout={3000}
                />
              ) : (
                <Input
                  type="date"
                  value={endDate || ''}
                  onChange={(e) => handleDateChange('endDate', new Date(e.target.value))}
                />
              )
            ) : (
              <DatePicker
                className="form-control"
                id="to"
                filterDate={(date) =>
                  date.getYear() > 108 && date.getYear() <= new Date().getYear()
                }
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dateFormat="dd/MM/yyy"
                selected={endDate ? new Date(endDate) : null}
                onChange={(date) => handleDateChange('endDate', date)}
                openToDate={new Date()}
                locale="it"
                selectsEnd
                startDate={new Date(startDate)}
                endDate={new Date(endDate)}
                popperModifiers={{
                  offset: {
                    enabled: true,
                    offset: '5px, 10px',
                  },
                  preventOverflow: {
                    enabled: true,
                    escapeWithReference: false,
                    boundariesElement: 'viewport',
                  },
                }}
              />
            )}
          </InputGroup>
        </Col>
      </Row>
    </div>
  )
}

export function FiltroAnno(props) {
  const {
    small,
    onDateFilterChange,
    dateSelected,
    showLastMonth,
    showLastYear,
    placeholder,
    yearDefaultValue,
  } = props
  const { dateType, startDate, endDate, year } = dateSelected

  const [inputYearError, setInputYearError] = useState(false)
  const [inputYearErrorMessage, setInputYearErrorMessage] = useState(null)

  const disableYearMonthFilters = Boolean(dateType === 'interval' && (startDate || endDate))
  const disableYearInput = dateType === 'lastYear' || dateType === 'lastMonth'

  const handleInputYearChange = (value) => {
    const currentYear = new Date().getFullYear()
    const isValidYear = /^(1[8,9]|20)\d{2}$/.test(value) && value <= currentYear

    if (isNaN(value)) {
      setInputYearError(true)
      setInputYearErrorMessage(ERROR.ONLYNUMBERS)
      return
    }

    if (value.length === 4 && !isValidYear) {
      setInputYearError(true)
      setInputYearErrorMessage(ERROR.INVALIDYEAR)
      return
    }

    if (value.length > 4) {
      setInputYearError(true)
      setInputYearErrorMessage(ERROR.TOOLONG)
      return
    }

    if (value.length > 0 && value.length < 4) {
      setInputYearError(true)
      setInputYearErrorMessage(ERROR.TOOSHORT)
      return
    }

    if (value.length === 0) {
      setInputYearError(false)
      setInputYearErrorMessage(null)
    }

    setInputYearError(false)
    setInputYearErrorMessage(null)

    onDateFilterChange({
      ...dateSelected,
      dateType: value ? TYPE.YEAR : null,
      year: value,
    })
  }

  const handleButtonClick = (clickedType) => {
    let newType
    clickedType === dateType ? (newType = null) : (newType = clickedType)

    onDateFilterChange({
      ...dateSelected,
      dateType: newType,
      year: null,
    })
  }

  return (
    <div id="filtro-anno">
      <Row>
        <Col sm={12} lg={small ? 12 : 3}>
          <Input
            type="text"
            invalid={inputYearError}
            onChange={(e) => handleInputYearChange(e.target.value)}
            disabled={disableYearMonthFilters || disableYearInput}
            defaultValue={yearDefaultValue || year}
            placeholder={placeholder || null}
          />
          {inputYearError && <FormFeedback>{inputYearErrorMessage}</FormFeedback>}
          <FormText color={small && 'white'}>Inserisci un anno per filtrare la ricerca</FormText>
        </Col>
        <Col className={classNames({ 'mt-3': small })}>
          {showLastYear && (
            <Button
              className={classNames('filtro-data', { active: dateType === TYPE.LASTYEAR })}
              onClick={(e) => handleButtonClick(TYPE.LASTYEAR)}
              disabled={disableYearMonthFilters}
            >
              Ultimo anno
              {dateType === TYPE.LASTYEAR && <FontAwesomeIcon icon="check" className="ml-2" />}
            </Button>
          )}
          {showLastMonth && (
            <Button
              className={classNames('filtro-data', { active: dateType === TYPE.LASTMONTH })}
              onClick={(e) => handleButtonClick(TYPE.LASTMONTH)}
              disabled={disableYearMonthFilters}
            >
              Ultimo mese
              {dateType === TYPE.LASTMONTH && <FontAwesomeIcon icon="check" className="ml-2" />}
            </Button>
          )}
        </Col>
      </Row>
    </div>
  )
}
