import React from 'react'
import _ from 'lodash'
import {Pagination, PaginationItem, PaginationLink} from 'reactstrap'

export default function G4Pagination({ currentPage, pageTotal, pageDelta, onPageChange }) {
  return (
    <Pagination>
      {currentPage > 0 && (
        <>
          <PaginationItem>
            <PaginationLink first onClick={() => onPageChange(null)} />
          </PaginationItem>
          <PaginationItem>
            <PaginationLink previous onClick={() => onPageChange(currentPage - 1)} />
          </PaginationItem>
        </>
      )}

      {currentPage + 1 - pageDelta > 0 && (
        <PaginationItem>
          <PaginationLink tag="span">…</PaginationLink>
        </PaginationItem>
      )}

      {_.times(pageTotal).map((p) => {
        if (p - 1 < currentPage - pageDelta || p + 1 > currentPage + pageDelta) {
          return false
        } else {
          return (
            <PaginationItem key={p} active={p === currentPage}>
              <PaginationLink onClick={() => onPageChange(p)}>{p + 1}</PaginationLink>
            </PaginationItem>
          )
        }
      })}

      {pageTotal > pageDelta + currentPage && (
        <PaginationItem>
          <PaginationLink tag="span">…</PaginationLink>
        </PaginationItem>
      )}

      {currentPage < pageTotal - 1 && (
        <>
          <PaginationItem>
            <PaginationLink next onClick={() => onPageChange(currentPage + 1)} />
          </PaginationItem>
          <PaginationItem>
            <PaginationLink last onClick={() => onPageChange(pageTotal - 1)} />
          </PaginationItem>
        </>
      )}
    </Pagination>
  )
}
