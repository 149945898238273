import React, { useState } from 'react'
import I from 'immutable'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

export default function TreeView({ voices, onSelect, selected }) {
  const [ulsOpen, setUlsOpen] = useState(I.Set([]))

  return (
    <div className="TreeView">
      <ul className="lev-0">
        {voices
          .filter((v) => v.lev === 0)
          .map((v) => (
            <TreeViewVoice
              voice={v}
              voices={voices}
              onSelect={onSelect}
              key={v.id}
              selected={selected}
              ulsOpen={ulsOpen}
              setUlsOpen={setUlsOpen}
            />
          ))}
      </ul>
    </div>
  )
}

function TreeViewVoice({ voice, voices, onSelect, selected, ulsOpen, setUlsOpen }) {
  const children = voices.filter((v) => v.parent === voice.id)
  const voiceSelected = selected.has(voice.id)
  return (
    <li className={classNames(`lev-${voice.lev}`, { selected: voiceSelected })}>
      <div className="d-flex align-items-center">
        <span
          className={classNames('arrow', {
            invisible: !(children.length > 0),
            'pr-0': ulsOpen.has(voice.id),
          })}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            setUlsOpen(SetToggle(ulsOpen, voice.id))
          }}
        >
          <FontAwesomeIcon
            icon={ulsOpen && ulsOpen.has(voice.id) ? 'caret-down' : 'caret-right'}
            className="text-blue"
          />
        </span>

        <span className="list-voice-container" onClick={(e) => onSelect(voice.id)}>
          <span>{voice.tit}</span>
          <span className={classNames('check ml-auto', { 'd-none': !voiceSelected })}>
            <FontAwesomeIcon icon={faCheck} />
          </span>
        </span>
      </div>
      {children.length > 0 && (
        <ul className={classNames({ open: ulsOpen && ulsOpen.has(voice.id) })}>
          {children.map((c) => (
            <TreeViewVoice
              voice={c}
              voices={voices}
              onSelect={onSelect}
              selected={selected}
              key={c.id}
              ulsOpen={ulsOpen}
              setUlsOpen={setUlsOpen}
            />
          ))}
        </ul>
      )}
    </li>
  )
}

function SetToggle(set, value) {
  return set && set.has(value) ? set.delete(value) : set.add(value)
}
