import React, { Component } from 'react'
import I from 'immutable'
import _ from 'lodash'
import classNames from 'classnames'
import { withRouter } from 'react-router-dom'
import { Row, Col, FormGroup, Label, Input, Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DebounceInput } from 'react-debounce-input'
import { stringifyQuery } from '../misc/utils'

import G4Scrollbar from '../misc/G4Scrollbar'
import SearchCategoria from './SearchCategoria'
import { FiltroIntervalloDate, FiltroAnno } from './FiltriDataAnno'
import { withAppContext } from '../misc/AppContext'
import Loading from '../misc/Loading'

export class Ricerca extends Component {
  constructor(props) {
    super(props)
    this.state = this.initialState()
  }

  // imposto la query di ricerca come state per precompilare la form
  // di ricerca se ho già parametri nell'url
  initialState = () => {
    const { sidebar } = this.props
    const { search } = this.props.location
    const params = new URLSearchParams(search)
    const testo = params.get('q') || ''
    const numero = params.get('numero')
    const argomenti = params.has('sez')
      ? I.Set(
          params
            .get('sez')
            .split(',')
            .map((x) => Number(x))
        )
      : I.Set([])
    const categorie = params.has('cat')
      ? I.Set(params.get('cat').split(',')).map((x) => Number(x))
      : I.Set([])

    if (sidebar) {
      return {
        testo: testo,
        argomenti: argomenti,
        categorie: categorie,
        numero: numero,
        data: {
          dateType: params.get('dateType'),
          year: params.get('year'),
          startDate: params.get('startDate'),
          endDate: params.get('endDate'),
        },
      }
    }
    return {
      testo: '',
      argomenti: I.Set([]),
      categorie: I.Set([]),
      numero: '',
      data: {
        dateType: null,
        year: null,
        startDate: null,
        endDate: null,
      },
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { sidebar } = this.props
    if (sidebar && !_.isEqual(prevProps.location, this.props.location)) {
      this.setState(this.initialState())
    }
  }

  // Handler degli input per la ricerca
  // se la ricerca è in sidebar (siamo in risultati ricerca),
  // dopo il setState di ogni handler, chiamo il submit Ricerca

  handleTesto = (newValue) => {
    this.setState(
      {
        testo: newValue,
      },
      this.props.sidebar && this.handleSubmitRicerca
    )
  }

  handleArgomenti = (value) => {
    const { argomenti } = this.state

    this.setState(
      {
        argomenti: argomenti.has(value) ? argomenti.delete(value) : argomenti.add(value),
      },
      this.props.sidebar && this.handleSubmitRicerca
    )
  }

  handleCategoria = (categorie) => {
    this.setState(
      {
        categorie: categorie,
      },
      this.props.sidebar && this.handleSubmitRicerca
    )
  }

  handleNumeroDocumento = (numero) => {
    this.setState(
      {
        numero: numero,
      },
      this.props.sidebar && this.handleSubmitRicerca
    )
  }

  handleDate = ({ dateType, startDate, endDate, year }) => {
    this.setState(
      {
        data: {
          dateType: dateType,
          startDate: startDate,
          endDate: endDate,
          year: year,
        },
      },
      this.props.sidebar && this.handleSubmitRicerca
    )
  }

  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.handleSubmitRicerca()
    }
  }

  handleSubmitRicerca = (e) => {
    if (e) e.preventDefault()
    const { sezioni, jSezioni } = this.props.appContext
    const { testo, argomenti, categorie, data, numero } = this.state
    const cleanCat = argomenti.isEmpty() || jSezioni.filter((s) => argomenti.has(s.id)).size
    const cleanNum = argomenti.isEmpty() || sezioni.filter((s) => argomenti.has(s.id)).size

    let url = '/risultati?'

    let paramsObj = {}
    paramsObj.q = testo
    paramsObj.sez = argomenti.join()
    paramsObj.cat = cleanCat ? '' : categorie.join()
    paramsObj.numero = cleanNum ? '' : numero
    paramsObj.dateType = data.dateType
    paramsObj.startDate = data.startDate
    paramsObj.endDate = data.endDate
    paramsObj.year = data.year

    const params = stringifyQuery(paramsObj)

    this.props.history.push(url + params)

    if (this.props.onClose) this.props.onClose()
  }

  render() {
    const { sidebar } = this.props
    const { sezioni, jSezioni } = this.props.appContext
    const { argomenti, testo, categorie, data, numero } = this.state
    const jSezSet = I.Set(jSezioni.map((s) => s.id).toList())
    const fSezSet = I.Set(sezioni.map((s) => s.id).toList())
    const showCategoryFilter =
      argomenti.filter((arg) => fSezSet.has(arg)).size &&
      !argomenti.filter((arg) => jSezSet.has(arg)).size
    const showNumeroDocumentoFilter =
      argomenti.filter((arg) => jSezSet.has(arg)).size &&
      !argomenti.filter((arg) => fSezSet.has(arg)).size

    return (
      <div
        className={classNames('Ricerca', {
          sidebar,
        })}
        onClick={(e) => e.stopPropagation()}
      >
        <G4Scrollbar sidebar={sidebar} noScrollX>
          <Row>
            <Col lg={12}>
              <div className="titolo">
                <span>
                  <FontAwesomeIcon icon="search" />
                  <FontAwesomeIcon icon="bolt" />
                </span>
                <h2>Ricerca Avanzata</h2>
              </div>
              <div className="info mt-2">
                <p>Digita il testo da cercare e utilizza i filtri per affinare la tua ricerca.</p>
              </div>
              <div className="form">
                <FormGroup>
                  {sidebar ? (
                    <div className="input-testo">
                      <div>
                        <FontAwesomeIcon icon="search" />
                      </div>
                      <DebounceInput
                        value={testo}
                        className="form-control"
                        minLength={3}
                        debounceTimeout={300}
                        onChange={(e) => this.handleTesto(e.target.value)}
                        placeholder="Cerca fra i risultati"
                      />
                    </div>
                  ) : (
                    <Input
                      placeholder="Cerca un testo"
                      value={testo}
                      onChange={(e) => this.handleTesto(e.target.value)}
                      onKeyPress={(e) => this.handleKeyPress(e)}
                    />
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="data">Filtra per data:</Label>
                  <FiltroIntervalloDate
                    dateSelected={data}
                    onDateFilterChange={this.handleDate}
                    small={sidebar}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="data">Filtra per anno:</Label>
                  <FiltroAnno
                    dateSelected={data}
                    onDateFilterChange={this.handleDate}
                    small={sidebar}
                    showLastMonth
                    showLastYear
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Filtra per argomento:</Label>
                  <div id="argomenti-editoria">
                    <div className="arg-label">Editoria:</div>
                    <div>
                      {sezioni
                        .valueSeq()
                        .filter((s) => s.lev === 0)
                        .map((arg) => (
                          <Button
                            key={arg.id}
                            className={classNames('argomento', {
                              active: argomenti.has(arg.id),
                            })}
                            onClick={(e) => this.handleArgomenti(arg.id)}
                          >
                            {arg.tit}
                            {argomenti.has(arg.id) && (
                              <FontAwesomeIcon icon="check" className="ml-2" />
                            )}
                          </Button>
                        ))}
                    </div>
                  </div>
                </FormGroup>
                {!!showCategoryFilter && (
                  <FormGroup>
                    <Label for="categorie">Filtra per categoria:</Label>
                    <Loading
                      value={categorie}
                      render={() => (
                        <SearchCategoria
                          selected={categorie}
                          onSelected={this.handleCategoria}
                          small={sidebar}
                        />
                      )}
                    />
                  </FormGroup>
                )}
                <FormGroup>
                  <div id="argomenti-banca">
                    <div>
                      {jSezioni.size > 0 && <div className="arg-label">Banca Dati:</div>}

                      {jSezioni
                        .valueSeq()
                        .filter((s) => s.lev === 0)
                        .map((arg) => (
                          <Button
                            key={arg.id}
                            className={classNames('argomento', {
                              active: argomenti.has(arg.id),
                            })}
                            onClick={(e) => this.handleArgomenti(arg.id)}
                          >
                            {arg.tit}
                            {argomenti.has(arg.id) && (
                              <FontAwesomeIcon icon="check" className="ml-2" />
                            )}
                          </Button>
                        ))}
                    </div>
                  </div>
                </FormGroup>
                {!!showNumeroDocumentoFilter && (
                  <FormGroup>
                    <Label for="categorie">Filtra per numero documento:</Label>
                    <Input
                      value={numero}
                      onChange={(e) => this.handleNumeroDocumento(e.target.value)}
                      placeholder={'Digita il numero identificativo del documento di tuo interesse'}
                    />
                  </FormGroup>
                )}
                {!sidebar && (
                  <Button className="mt-3 submit" onClick={(e) => this.handleSubmitRicerca(e)}>
                    <FontAwesomeIcon icon="search" className="mr-2" />
                    Cerca
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </G4Scrollbar>
      </div>
    )
  }
}

export default withAppContext(withRouter(Ricerca))
