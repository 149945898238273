import React from 'react'
import classNames from 'classnames'
import { NavLink, withRouter } from 'react-router-dom'
import { Nav, NavItem, NavLink as BNavLink } from 'reactstrap'
import { withAppContext } from './AppContext'

const getParent = (sezioni, sez) => {
  return sezioni.get(sez.parent)
}

// funzione che a partire dalla sezione corrente (o dalla sezione che contiene la news correntemente
// visualizzata), ritorna un array di sezioni risalendo l'albero della parentela
const getSezioniBreadcrumb = (currSez, sezioni) => {
  let sez_bc = []
  // se non ho un parent, sono una sezione root, quindi l'array di sezioni per il breadcrumb
  // conterrà solo me stessa
  if (currSez.parent === null) {
    return (sez_bc = [currSez])
  }

  // altrimenti, inserisco nell'array me stessa più il mio parent. Poi, se il mio parent ha un parent,
  // risalgo l'albero della parentela aggiungendo sezione per sezione, fino a che non trovo una sezione
  // che non abbia parent, ovvero che sia una root
  sez_bc.unshift(currSez)
  let parent = getParent(sezioni, currSez)
  sez_bc.unshift(parent)

  while (parent.parent !== null) {
    parent = getParent(sezioni, parent)
    sez_bc.unshift(parent)
  }

  return sez_bc
}

function Breadcrumb({ newsSez, source, ...props }) {
  const { slug } = props.match.params
  const { sezioni, jSezioni } = props.appContext
  let currSez
  // A seconda della provenienza del dato, passo allla funzione che creerà il breadcrumb:
  // la sezione corrente corretta
  // l'insieme di sezioni corretto
  let sezList
  switch (source) {
    case 'f':
      currSez = sezioni.get(newsSez) || sezioni.find((s) => s.slug === slug)
      sezList = sezioni
      break
    case 'j':
      currSez = jSezioni.get(newsSez) || jSezioni.find((s) => s.slug === slug)
      sezList = jSezioni
      break
    default:
      break
  }
  // se è presente una sezione corrente (non siamo in home), chiamo la funzione per popolare
  // l'array di sezioni che comporranno il breadcrumb, altrimenti sez_bc sarà un array vuoto
  const sez_bc = currSez ? getSezioniBreadcrumb(currSez, sezList) : []

  return (
    <Nav className="Breadcrumb">
      <NavItem>
        <BNavLink tag={NavLink} to="/">
          <span>Home</span>
          <span className="divider">/</span>
        </BNavLink>
      </NavItem>
      {sez_bc.map((sez, i) => {
        return (
          <NavItem key={sez.id}>
            <BNavLink
              tag={NavLink}
              to={`/${sez.slug}`}
              disabled={
                (sez.id === currSez.id && !newsSez) ||
                (sez.lev === 1 && sezList.filter((s) => s.parent === sez.id).size > 0)
              }
              className={classNames({
                current: sez.id === currSez.id,
                active: sez.id === currSez.id,
              })}
            >
              <span>{sez.tit}</span>
              {sez_bc.indexOf(sez) + 1 !== sez_bc.length && <span className="divider">/</span>}
            </BNavLink>
          </NavItem>
        )
      })}
    </Nav>
  )
}

export default withAppContext(withRouter(Breadcrumb))
