import React, {Fragment, useState} from 'react'
import classNames from 'classnames'
import {Button} from 'reactstrap'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Ricerca from './Ricerca'

export default function ModificaRicercaMobile(props) {
  const [isModificaRicercaOpen, setModificaRicercaOpen] = useState(false)

  const toggle = () => setModificaRicercaOpen(!isModificaRicercaOpen)

  return (
    <Fragment>
      <Button
        className="btn-ricerca modifica d-block d-lg-none"
        onClick={toggle}
      >
        <FontAwesomeIcon icon="filter" />
        <span className="ml-3">Filtri</span>
      </Button>
      {isModificaRicercaOpen && (
        <div
          id="mobile-ricerca-wrapper"
          className={classNames({
            open: isModificaRicercaOpen,
            closed: !isModificaRicercaOpen,
          })}
          onClick={e => setModificaRicercaOpen(false)}
        >
          <Ricerca sidebar mobile />
        </div>
      )}
    </Fragment>
  )
}
