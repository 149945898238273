import React from 'react'
import ErrorBoundary from './error-boundary'
import LoggedRoute from '../misc/LoggedRoute'

export const ErrorBoundaryRoute = ({component: Component, ...rest}) => {
  if (!Component)
    throw new Error(`A component needs to be specified for path ${rest.path}`)

  return (
    <LoggedRoute {...rest}>
      <ErrorBoundary>
        <Component />
      </ErrorBoundary>
    </LoggedRoute>
  )
}

export default ErrorBoundaryRoute
