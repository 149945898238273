import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { Row, Col, Button } from 'reactstrap'
import Moment from 'react-moment'
import classNames from 'classnames'
import ReactToPrint from 'react-to-print'

import { withAppContext } from '../shared/misc/AppContext'
import Layout from '../shared/layout/Layout'
import RicercaModal from '../shared/components/RicercaModal'
import Breadcrumb from '../shared/misc/Breadcrumb'
import { fetchDataOrError, NotFound } from '../shared/misc/utils'
import Loading from '../shared/misc/Loading'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { DEV } from '../shared/misc/utils'
import logo from '../images/logo-fiscal-box.png'
import '../images/DA1A645F2AD99101.png'
import '../styles/print.scss'
import PageNotFound from '../shared/misc/PageNotFound'

class Notizia extends Component {
  state = {
    news: null,
  }

  async componentDidMount() {
    const url = DEV ? '/api/news_70841.json' : `/api/fiscal/articolo/${this.props.match.params.id}`
    const resp = await fetchDataOrError(url)

    this.setState({ news: resp })
  }

  render() {
    const { news, print } = this.state

    if (news === NotFound) {
      return <PageNotFound />
    }

    return (
      <Layout className="Notizia">
        <Loading
          value={news}
          render={() => {
            return (
              <Fragment>
                <Row className="mt-5">
                  <Col sm={12}>
                    <Breadcrumb newsSez={news.sez} source="f" />
                  </Col>
                </Row>
                <Row className="mt-3 align-content-center justify-content-between header">
                  <Col sm={12} md="auto" className="d-flex">
                    <h5 className="data">
                      <Moment format="D MMMM YYYY" date={news.data} />
                    </h5>
                    <ReactToPrint
                      trigger={() => (
                        <Button id="print" className="d-print-none">
                          <FontAwesomeIcon icon="print" className="mr-2" />
                          <span>Stampa</span>
                        </Button>
                      )}
                      content={() => this.componentRef}
                      bodyClass="print"
                    />
                  </Col>
                  <Col sm={12} md="auto" className="text-right">
                    <RicercaModal />
                  </Col>
                </Row>

                <News news={news} ref={(el) => (this.componentRef = el)} print={print} />
              </Fragment>
            )
          }}
        />
      </Layout>
    )
  }
}

class News extends Component {
  render() {
    const { news } = this.props
    const { tit, stit, info, testo, data, fonte, firma } = news
    const anyContent = !!(stit || info || testo)

    return (
      <div id="news">
        <div className="logo-fiscal-box d-none d-print-block">
          <img src={logo} alt="Fiscal box" />
        </div>

        <h5 className="data d-none d-print-block" style={{ display: 'none' }}>
          <Moment format="D MMMM YYYY" date={data} />
        </h5>

        <Row className="mt-3">
          <Col sm={12}>
            <h1 className={classNames('titolo-pagina', { 'margin-bottom': firma })}>{tit}</h1>
            {firma && (
              <p className="firma">
                Autore: <span>{firma}</span>
              </p>
            )}
          </Col>
        </Row>
        <Row className="mt-3">
          {anyContent && (
            <Col lg={8}>
              <div className="white-box content">
                {stit && <h2>{stit}</h2>}
                {info && <h3>{info}</h3>}
                {testo && <div className="p" dangerouslySetInnerHTML={{ __html: testo }} />}
                {fonte && (
                  <p className="fonte">
                    <strong>{fonte}</strong>
                  </p>
                )}
              </div>
            </Col>
          )}
          <Col lg={anyContent ? 4 : 12} className="d-print-none">
            <div className="white-box azioni">
              <div className="header">Documenti Allegati</div>
              <div className="allegati-container">
                <h5>Allegati</h5>
                <div className="allegati">
                  {news.all.length === 0 && (
                    <span className="noAll">Questo articolo non ha documenti scaricabili</span>
                  )}
                  {news.all.map((all, i) => (
                    <p
                      key={i}
                      className={classNames('allegato', {
                        column: !anyContent,
                      })}
                    >
                      <a
                        href={`https://www.fiscal-focus.it/${all.url}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FontAwesomeIcon icon="file-download" className="mr-2" />
                        <span className="nome">{all.nome}</span>
                      </a>
                    </p>
                  ))}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default withAppContext(withRouter(Notizia))
