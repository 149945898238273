import React, { Component } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import Sidebar from './Sidebar'
import Submenu from './Submenu'
import Topbar from './Topbar'
import Loading from '../misc/Loading'
import Ricerca from '../components/Ricerca'
import { withAppContext } from '../misc/AppContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class Layout extends Component {
  state = {
    isMobileMenuOpen: false,
    isDesktopSubMenuOpen: true,
  }

  componentDidUpdate(prevProps) {
    const { key } = this.props.location
    const { key: prevKey } = prevProps.location
    if (!(prevKey === key)) {
      this.setState({ isDesktopSubMenuOpen: true, isMobileMenuOpen: false })
    }
  }

  setMobileMenuOpen = () => {
    this.setState((prevState) => ({
      isMobileMenuOpen: !prevState.isMobileMenuOpen,
    }))
  }

  setDesktopSubMenuOpen = () => {
    this.setState((prevState) => ({
      isDesktopSubMenuOpen: !prevState.isDesktopSubMenuOpen,
    }))
  }

  render() {
    const { appContext, hasRicerca, hasSubmenu, className, children } = this.props
    const { utente, sezioni, categorie, getData } = appContext
    const { isMobileMenuOpen, isDesktopSubMenuOpen } = this.state

    return (
      <Loading
        value={[utente, sezioni, categorie]}
        onRetry={getData}
        render={() => (
          <Container
            fluid
            className={classNames(`Layout ${className}`, {
              'menu-mobile-open': isMobileMenuOpen,
            })}
            id="wrapper"
          >
            <Row>
              <Col
                id="sidebar-wrapper"
                className={classNames({
                  open: isMobileMenuOpen,
                  closed: !isMobileMenuOpen,
                })}
              >
                <div className="toggler close-btn" onClick={this.setMobileMenuOpen}>
                  <FontAwesomeIcon icon="times" />
                </div>
                <Sidebar
                  isMobileMenuOpen={isMobileMenuOpen}
                  isDesktopSubMenuOpen={isDesktopSubMenuOpen}
                  setDesktopSubMenuOpen={this.setDesktopSubMenuOpen}
                />
              </Col>
              {/* colonna sottomenu in Sezione */}
              {hasSubmenu && (
                <Col
                  id="submenu-wrapper"
                  className={classNames({
                    open: isDesktopSubMenuOpen,
                    closed: !isDesktopSubMenuOpen,
                  })}
                >
                  <Submenu setDesktopSubMenuOpen={this.setDesktopSubMenuOpen} />
                </Col>
              )}
              {/* colonna ricerca in Risultati */}
              {hasRicerca && (
                <Col
                  id="submenu-wrapper"
                  className={classNames({
                    open: isDesktopSubMenuOpen,
                    closed: !isDesktopSubMenuOpen,
                  })}
                >
                  <Ricerca sidebar />
                </Col>
              )}
              <Col id="page-content-wrapper" className="px-0">
                <Container fluid>
                  <Topbar
                    isDesktopSubMenuOpen={isDesktopSubMenuOpen}
                    setMobileMenuOpen={this.setMobileMenuOpen}
                  />
                  <main>{children}</main>
                </Container>
              </Col>
            </Row>
          </Container>
        )}
      />
    )
  }
}
export default withAppContext(withRouter(Layout))
