import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'

import ScrollToTop from './shared/misc/ScrollToTop'
import App from './App'
import 'moment/locale/it'

import 'bootstrap/dist/css/bootstrap.min.css'
import './styles/styles.scss'
import * as serviceWorker from './serviceWorker'

// Icone Fontawesome 5, rese disponibili a tutti i children di App
import { library } from '@fortawesome/fontawesome-svg-core'
import * as fas from '@fortawesome/pro-solid-svg-icons'

library.add(
  fas.faHome,
  fas.faNewspaper,
  fas.faReceipt,
  fas.faBriefcase,
  fas.faQuestionCircle,
  fas.faGavel,
  fas.faBolt,
  fas.faSearch,
  fas.faUser,
  fas.faCalendarAlt,
  fas.faCheck,
  fas.faTimes,
  fas.faListAlt,
  fas.faSortAmountUp,
  fas.faSortAmountDown,
  fas.faPlus,
  fas.faArrowLeft,
  fas.faArrowRight,
  fas.faFileDownload,
  fas.faEye,
  fas.faArrowCircleUp,
  fas.faBars,
  fas.faFilter,
  fas.faPrint,
  fas.faPercentage,
  fas.faBooks,
  fas.faBalanceScaleRight,
  fas.faAngleUp,
  fas.faCaretDown,
  fas.faCaretRight,
  fas.faInfoCircle,
  fas.faMegaphone,
)

ReactDOM.render(
  <Router>
    <ScrollToTop>
      <App />
    </ScrollToTop>
  </Router>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
