import React, {Component} from 'react'
import I from 'immutable'
import Autosuggest from 'react-autosuggest'
import autosuggestMatch from 'autosuggest-highlight/match'
import autosuggestParse from 'autosuggest-highlight/parse'
import {Badge} from 'reactstrap'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import {withAppContext} from '../misc/AppContext'

class SearchCategoria extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: '',
      suggestions: [],
      selected: I.Set(),
    }
  }

  componentDidMount() {
    const {categorie} = this.props.appContext

    const {selected} = this.props
    const cate_selected = selected.map(x =>
      categorie.find(c => c.id === Number(x))
    )

    this.setState({selected: cate_selected})
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.selected.equals(this.state.selected)) {
      const {selected} = this.state
      const cate_selected = selected.map(c => c.id)
      this.props.onSelected(cate_selected)
    }
  }

  getSuggestions = value => {
    const inputValue = value.trim().toLowerCase()
    const inputLength = inputValue.length
    const {categorie} = this.props.appContext
    const {selected} = this.state
    const selectedIds = selected.map(sel => sel.id)

    return inputLength === 0
      ? []
      : categorie.filter(
          cat =>
            cat.tit.toLowerCase().slice(0, inputLength) === inputValue &&
            !selectedIds.has(cat.id)
        )
  }

  getSuggestionValue = suggestion => suggestion.tit

  onChange = (ev, {newValue}) => {
    this.setState({value: newValue})
  }

  onSuggestionsFetchRequested = ({value}) => {
    this.setState({suggestions: this.getSuggestions(value)})
  }

  clearSuggestions = () => {
    this.setState({suggestions: [], value: ''})
  }

  onSuggestionSelected = (ev, {suggestion}) => {
    this.clearSuggestions()
    this.setState({
      selected: this.state.selected.add(suggestion),
    })
  }

  removeSelected = cate => {
    this.setState({
      selected: this.state.selected.delete(cate),
    })
  }

  renderSuggestion = (suggestion, {query, isHighlighted}) => {
    const matches = autosuggestMatch(suggestion.tit, query)
    const parts = autosuggestParse(suggestion.tit, matches)

    return (
      <div className="suggestion">
        <div className="icon">
          <span>C</span>
        </div>
        <div className="name">
          {parts.map((p, i) => {
            const className = p.highlight
              ? 'react-autosuggest__suggestion-match'
              : null

            return (
              <span className={className} key={p + i}>
                {p.text}
              </span>
            )
          })}
        </div>
      </div>
    )
  }

  handleKeyPress = e => {
    if (e.key === 'Enter') {
      const {suggestions} = this.state
      if (suggestions.length === 0) {
        return
      }
      this.setState({
        selected: this.state.selected.add(suggestions[0]),
        value: '',
      })
      this.clearSuggestions()
    }
  }

  render() {
    const {selected} = this.state
    const {small} = this.props
    let placeholder
    if (small) {
      placeholder = `Digita ${
        selected.size === 0 ? 'una' : "un'altra"
      } categoria`
    } else {
      placeholder = `Digita ${
        selected.size === 0 ? 'una' : "un'altra"
      } categoria di tuo interesse`
    }
    return (
      <div className="SearchCategoria">
        {selected.size > 0 && (
          <div className="selected-categories">
            {selected.map(cate => (
              <Badge
                key={cate.id}
                className="mr-2"
                onClick={e => this.removeSelected(cate)}
              >
                {cate.tit}
                <FontAwesomeIcon icon="times" className="ml-2" />
              </Badge>
            ))}
          </div>
        )}
        <Autosuggest
          suggestions={this.state.suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.clearSuggestions}
          onSuggestionSelected={this.onSuggestionSelected}
          getSuggestionValue={this.getSuggestionValue}
          renderSuggestion={this.renderSuggestion}
          inputProps={{
            className: 'form-control',
            value: this.state.value,
            placeholder: placeholder,
            onChange: this.onChange,
            onKeyPress: e => this.handleKeyPress(e),
          }}
        />
      </div>
    )
  }
}

export default withAppContext(SearchCategoria)
