import React from 'react'

// Creo il context con un default
const AppContext = React.createContext({
  user: null,
  sezioni: [],
  categorie: [],
})

export function withAppContext(Component) {
  return function AppContextComponent(props) {
    return (
      <AppContext.Consumer>
        {appContext => <Component appContext={appContext} {...props} />}
      </AppContext.Consumer>
    )
  }
}

export default AppContext
