import React from 'react'
import classNames from 'classnames'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {withAppContext} from '../misc/AppContext'
import TopbarLogin from '../misc/TopbarLogin'

function Topbar(props) {
  const {isDesktopSubMenuOpen, setMobileMenuOpen} = props
  const {utente} = props.appContext
  return (
    <div
      className={classNames('Topbar', {'no-submenu': !isDesktopSubMenuOpen})}
    >
      <nav className="navbar navbar-expand-lg navbar-light bg-light border-bottom">
        <button
          className="navbar-toggler"
          type="button"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={setMobileMenuOpen}
        >
          <span className="navbar-toggler-icon">
            <FontAwesomeIcon icon="bars" />
          </span>
        </button>

        <div className="ml-auto">
          <TopbarLogin utente={utente} />
        </div>
      </nav>
    </div>
  )
}

export default withAppContext(Topbar)
