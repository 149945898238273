import React, { Component } from 'react'
import { Container, Row, Col } from 'reactstrap'
import logo from '../../images/logo-fiscal-box.png'
import '../../images/DA1A645F2AD99101.png'

class ErrorBoundary extends Component {
  state = {
    error: undefined,
    errorInfo: undefined,
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo,
    })
  }

  render() {
    const { error, errorInfo } = this.state
    if (errorInfo) {
      const errorDetails =
        process.env.NODE_ENV === 'development' ? (
          <details className="preserve-space">
            {error && error.toString()}
            <br />
            {errorInfo.componentStack}
          </details>
        ) : undefined
      return (
        <Container>
          <Row className="justify-content-center align-content-center">
            <Col sm={12} md={10} lg={8} className="text-center my-auto">
              <img src={logo} alt="Fiscal box - La Banca Dati di Fiscal Focus" />
              <div>
                <h2 className="error">Si è verificato un errore inaspettato.</h2>
                <h3>Provare a ricaricare la pagina</h3>
                <p>Se il problema persiste, contattare l'assistenza.</p>
                {errorDetails}
              </div>
            </Col>
          </Row>
        </Container>
      )
    }
    return this.props.children
  }
}

export default ErrorBoundary
